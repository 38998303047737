@mixin size($size) {
  width: $size;
  height: $size;
  font-size: $size;
}

.material-icons-outlined,
.material-icons {
  display: inline-block;
  font-weight: normal;
  font-size: 2.4rem;  /* Preferred icon size */
  font-style: normal;
  line-height: 1 !important;  /* NGTC-5079 accessibility */
  direction: ltr;
  letter-spacing: normal;
  white-space: nowrap;
  text-transform: none;
  word-wrap: normal;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  /* Rules for sizing the icon. */
  &.md-10 {
    @include size(1rem);
  }

  &.md-12 {
    @include size(1.2rem);
  }

  &.md-13 {
    @include size(1.3rem);
  }

  &.md-14 {
    @include size(1.4rem);
  }

  &.md-15 {
    @include size(1.5rem);
  }

  &.md-20 {
    @include size(2rem);
  }

  &.md-26 {
    @include size(2.6rem);
  }

  &.md-35 {
    @include size(3.5rem);
  }
}

.material-icons {
  font-family: 'Material Icons';
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
}
