
/* lato-300 - latin */
@font-face {
  font-weight: 300;
  font-display: block;
  font-family: 'Lato';
  font-style: normal;
  src: url('/fonts/lato-v20-latin-300.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/lato-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/lato-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/lato-v20-latin-300.woff') format('woff'), /* Modern Browsers */
    url('/fonts/lato-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/lato-v20-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-regular - latin */
@font-face {
  font-weight: 400;
  font-display: block;
  font-family: 'Lato';
  font-style: normal;
  src: url('/fonts/lato-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/lato-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/lato-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/lato-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/fonts/lato-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/lato-v20-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700 - latin */
@font-face {
  font-weight: 700;
  font-display: block;
  font-family: 'Lato';
  font-style: normal;
  src: url('/fonts/lato-v20-latin-700.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/lato-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/lato-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/lato-v20-latin-700.woff') format('woff'), /* Modern Browsers */
    url('/fonts/lato-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/lato-v20-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
  font-weight: 400;
  font-display: block;
  font-family: 'Material Icons';
  font-style: normal;
  src: url('/fonts/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src:
    local('Material Icons'),
    local('MaterialIcons-Regular'),
    url('/fonts/MaterialIcons-Regular.woff2') format('woff2'),
    url('/fonts/MaterialIcons-Regular.woff') format('woff'),
    url('/fonts/MaterialIcons-Regular.ttf') format('truetype');
}

@font-face {
  font-weight: 400;
  font-display: block;
  font-family: 'Material Icons Outlined';
  font-style: normal;
  src: url('/fonts/material-icons-outlined.eot'); /* For IE6-8 */
  src:
    local('Material Icons Outlined'),
    local('MaterialIcons-Outlined'),
    url('/fonts/MaterialIconsOutlined-Regular.woff2') format('woff2'),
    url('/fonts/MaterialIconsOutlined-Regular.woff') format('woff'),
    url('/fonts/MaterialIconsOutlined-Regular.otf') format('opentype');
}
