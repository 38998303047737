$dark-primary-text: black;
$light-primary-text: white;

$custom-red-palette: (
  700: #ffb500,
  800: #de2839,
  900: #e00016,  // presence DND
  contrast: (
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

$custom-dark-mode-red-palette: (
  700: #ffda96,
  800: #ff6767,
  900: #ff6767, // presence DND
  contrast: (
    700: $light-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
  )
);
