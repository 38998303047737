$light-primary-text: white;
$dark-primary-text: black;
$dark-mode-green: #30db19;
$light-mode-green: #227f18;
$custom-blue: #0d68a1;

// link colors
$light-link: #0e76b2;
$light-link-hovered: #033a64;
$light-link-visited: #550082;
$dark-link: #91c8e5;
$dark-link-hovered: #b7e6fd;
$dark-link-visited: #d996fc;
