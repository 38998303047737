@import 'colors';

a {
  &:link {
    color: $light-link;
  }

  &:visited {
    color: $light-link-visited;
  }

  &:hover:not(.stacked-link, .no-underline) {
    color: $light-link-hovered;
  }

  @media (prefers-color-scheme: dark) {
    &:link {
      color: $dark-link;
    }

    &:visited {
      color: $dark-link-visited;
    }

    &:hover:not(.stacked-link, .no-underline) {
      color: $dark-link-hovered;
    }
  }
}

a.stacked-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

a.no-underline {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
