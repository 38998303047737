/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import 'variables';
@import 'accessibility';
@import 'utils';

// Override the typography in the core CSS.
@include mat-core($custom-typography);
@include mat.core();

.focus-visible {
  @include mat.strong-focus-indicators((
    border-style: dotted,
    border-width: 3px,
    border-radius: inherit,
  ));
}

@media (prefers-color-scheme: light) {
  // Include theme styles for core and each component used in your app.
  // Alternatively, you can import and @include the theme mixins for each component
  // that you are using.
  //@include mat.all-component-colors($unify-app-theme);
  @include angular-material-theme($unify-app-theme);

  .focus-visible {
    @include mat.strong-focus-indicators-theme(black);

    //material overrides
    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
      outline: 0.3rem dotted black;
      outline-offset: 0.3rem;
    }
  }
}

@media (prefers-color-scheme: dark) {
  //@include angular-material-theme($dark-theme);
  @include mat.all-component-colors($dark-theme);

  .focus-visible {
    @include mat.strong-focus-indicators-theme(white);

    //material overrides
    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
      outline: 0.3rem dotted white;
      outline-offset: 0.3rem;
    }
  }
  //Overrides of material components
  @include mat-slide-toggle-color($dark-slide-toggle-theme);
  //Custom components
}

html,
body {
  height: 100%;
  margin: 0;
}

html {
  /* stylelint-disable-next-line unit-disallowed-list */
  font-size: 10px; // Starting base to use clean REM values}
}
