@font-face {
  font-weight: normal;
  font-family: 'UnifyPhoneSVG';
  font-style: normal;
  font-display: block;
  src:
    url('/fonts/UnifyPhoneIcons.eot?pkfhtg#iefix') format('embedded-opentype'),
    url('/fonts/UnifyPhoneIcons.ttf?pkfhtg') format('truetype'),
    url('/fonts/UnifyPhoneIcons.woff?pkfhtg') format('woff');
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-weight: normal;
  font-family: 'UnifyPhoneSVG' !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: never;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pickup-group-icon::before {
  content: "\e900";
}

.icon-deskphone-icon::before {
  content: "\e901";
}

.icon-outgoing-icon::before {
  content: "\e902";
}

.icon-pull-icon::before {
  content: "\e903";
}

.icon-transfer-icon::before {
  content: "\e904";
}

.icon-whats-new-icon::before {
  content: "\e905";
}
