@import 'variables';

.accessibility-element {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
}

.skip-link {
  position: absolute;
  top: 0.5rem;
  left: 50%;
  z-index: 500;
  padding: 0.25rem 1rem;
  border: 0.1rem solid $dark-primary-text;
  border-radius: 0.3rem;
  font-weight: bold;
  font-size: 1.8rem;
  text-decoration: none;
  background-color: $light-primary-text;
  outline: none;
  box-shadow:
    0 0.3rem 0.1rem -0.2rem rgb(0 0 0 / 20%),
    0 0.2rem 0.2rem 0 rgb(0 0 0 / 14%),
    0 0.1rem 0.5rem 0 rgb(0 0 0 / 12%);
  transform: translateX(-50%);
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);

  @media (prefers-color-scheme: dark) {
    //color: $dark-primary-text;
    background-color: $dark-primary-text;
  }
}

.skip-link:focus {
  clip: initial;
}
